<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onBack">뒤로</Button>
      <Button type="primary" @click="onSubmit">저장</Button>
    </template>

    <div class="page-content">
      <Form label-position="right" :label-width="120">
        <FormItem label="이름">
          <Input size="large" v-model="form.name" placeholder="휴대폰 번호"/>
        </FormItem>
        <FormItem label="코드">
          <Input size="large" v-model="form.code"/>
        </FormItem>
        <FormItem label="할인금액">
          <Input size="large" v-model="form.discount_price"/>
        </FormItem>
        <FormItem label="공개">
          <Checkbox size="large" v-model="form.is_open">해당 쿠폰을 공개합니다.</Checkbox>
        </FormItem>
        <FormItem label="수량제한">
          <Input size="large" v-model="form.limit"/>
        </FormItem>
        <FormItem label="활성 기간">
          <Row>
            <Col span="12">
              <CDatePicker size="large"
                           placeholder="시작일"
                           type="datetime"
                           v-model="form.start_at"
              ></CDatePicker>
            </Col>
            <Col span="12">
              <CDatePicker size="large"
                           placeholder="종료일"
                           type="datetime"
                           v-model="form.end_at"
              ></CDatePicker>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";

export default {
  props: ['id'],
  mixins: [editorMixin],
  computed: {
    apiUrl() {
      return this.isModify ? `coupon/${this.id}` : 'coupon'
    }
  },
  data() {
    return {
      form: {
        name: '',
        start_at: null,
        discount_price: null,
        end_at: null,
        code: null,
        is_open: false,
        limit: 0,
      }
    }
  },
  methods: {},
  mounted() {
    if (this.isModify) this.getData()
  }
}
</script>

<style lang="scss" scoped>
</style>